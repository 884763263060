<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>
<ng-template #expectedToReturned let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>This will set the Expected Unit Price to the Last Returned Unit Price, and the Expected Price Schedule to the Last Returned Price Schedule. Do you wish to Continue?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="setExpectedToReturnedPrice(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>
<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../assets/images/Get_Price.png" alt="Pricing Scenarios" style="padding-right: 5px;">
  Pricing Test Scenarios
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/pricing-scenarios']"
          routerLinkActive="router-link-active" title="Go Back to List">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">
    SAVE &
    NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
          (click)="showRemovePopUp(areYouSure)" title="Delete Record">
    DELETE
  </button>
  <button kendoButton type="splitbutton" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Execute_Multiple.png" (click)="execute()">
    EXECUTE
  </button>
  <button kendoButton type="splitbutton" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Expected_Returned.png" (click)="showRemovePopUp(expectedToReturned)">
    SET EXPECTED TO RETURNED
  </button>
</kendo-buttongroup>
<form [formGroup]="pricingScenarioForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Pricing Scenario Name">
        </kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Pricing Scenario Name"
                       maxlength="100">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="expectedUnitPriceAmount" text="Expected Unit Price"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="expectedUnitPriceAmount" [min]="0"
                              [autoCorrect]="true" [decimals]="5" [format]="'n5'" [max]="99999999999999" placeholder="Enter the Expected Unit Price">
        </kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="expectedPriceScheduleID" text="Expected Price Schedule"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select class="noarrow" id="expectedPriceScheduleID" [multiple]="false"
                     [selectOnTab]="true" formControlName="expectedPriceScheduleID" [ngClass]="{
                                'borderless': true,
                                'is-invalid': submitted && f.expectedPriceScheduleID.errors,
                                'w-100': true
                                }" [searchable]="true" placeholder="Select an Expected Price Schedule"
                     [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.ExpectedPriceSchedule)"
                     (change)="onSelectBoxChanged($event, 'expectedPriceScheduleID', expectedPriceSchedules)">
            <ng-option *ngFor="let item of expectedPriceSchedules" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_expectedPriceSchedule()"
                  icon="search"></button>
        </span>
      </kendo-formfield>
    </div>
  </div>
  <kendo-tabstrip>
    <kendo-tabstrip-tab title="Input Values" [selected]="true">
      <ng-template kendoTabContent>
        <div id="inputValues" class="card card-primary">
          <div class="card-body">
            <div class="row">
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="priceScheduleID" text="Price Schedule"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select id="priceScheduleID" class="noarrow" [multiple]="false"
                               [selectOnTab]="true" formControlName="priceScheduleID" [ngClass]="{
                                    'borderless': true,
                                    'w-100': true
                                    }" [searchable]="true" placeholder="(Dynamic if not specified)"
                               [notFoundText]="'Please start typing to search'"
                               title="Specify a Price Schedule if you want to test that specific pricing. Leave blank for Dynamic Pricing to search for the best Price Schedule."
                               (search)="search($event, filters.PriceSchedule)"
                               (change)="onSelectBoxChanged($event, 'priceScheduleID', priceSchedules)">
                      <ng-option *ngFor="let item of priceSchedules" [value]="item.id">
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear"
                            (click)="onLookup_priceSchedule()" icon="search"></button>
                  </span>
                </kendo-formfield>
              </div>
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="effectiveDate" text="Effective Date"></kendo-label>
                  <kendo-datepicker id="effectiveDate" class="form-control"
                                    formControlName="effectiveDate" [min]="min">
                  </kendo-datepicker>
                  <kendo-formerror>Pick a date greater than Jan 1st, 2000</kendo-formerror>
                </kendo-formfield>
              </div>

              <div class="col form-group">
                <kendo-formfield>
                    <input type="checkbox" id="useToday" formControlName="useToday" (change)="onUseTodayChanged()" #useToday kendoCheckBox />
                  <kendo-label class="k-checkbox-label" for="useToday" text="Use Today"></kendo-label>
                </kendo-formfield>
              </div>

              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="quantity" text="Quantity"></kendo-label>
                  <kendo-numerictextbox class="form-control" formControlName="quantity" [min]="0" [max]="99999999999999"
                                        [autoCorrect]="true" [decimals]="5" [format]="'n5'"
                                        placeholder="Enter a Quantity">
                  </kendo-numerictextbox>
                </kendo-formfield>
              </div>

            </div>
            <div class="row">
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="locationID" text="Location"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select id="locationID" class="noarrow" [multiple]="false"
                               [selectOnTab]="true" formControlName="locationID"
                               placeholder="Select a Location" [ngClass]="{
                                'borderless': true,
                                'is-invalid': submitted && f.locationID.errors,
                                'w-100': true
                                }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.Location)"
                               (change)="onSelectBoxChanged($event, 'locationID', locations)">
                      <ng-option *ngFor="let item of locations" [value]="item.id">
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear" (click)="onLookup_location()"
                            icon="search"></button>
                  </span>
                </kendo-formfield>
              </div>
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="valueFromERP" text="Value from ERP"></kendo-label>
                  <kendo-numerictextbox class="form-control" formControlName="valueFromERP" [min]="0" [max]="99999999999999"
                                        [autoCorrect]="true" [decimals]="5" [format]="'n5'"
                                        placeholder="Enter a Value from ERP">
                  </kendo-numerictextbox>
                </kendo-formfield>
              </div>
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="multiLineTag" text="MultiLine Tag"></kendo-label>
                  <kendo-textbox class="form-control" formControlName="multiLineTag" placeholder="Example: TagName - Sort#">
                  </kendo-textbox>
                </kendo-formfield>
              </div>
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="scenarioGroup" text="Scenario Group"></kendo-label>
                  <kendo-textbox class="form-control" formControlName="scenarioGroup" placeholder="Enter an optional Group">
                  </kendo-textbox>
                </kendo-formfield>
              </div>

            </div>
            <div class="row">
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="itemID" text="Item"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select class="noarrow" id="itemID" [multiple]="false"
                               [selectOnTab]="true" formControlName="itemID" [ngClass]="{
                                    'borderless': true,
                                    'w-100': true
                                    }" [searchable]="true" placeholder="Select an Item"
                               [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.Item)"
                               (change)="onSelectBoxChanged($event, 'itemID', items)">
                      <ng-option *ngFor="let item of items" [value]="item.id">
                        {{ item?.number }} - {{ item?.name }}
                      </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear" (click)="onLookup_item()"
                            icon="search"></button>
                  </span>
                </kendo-formfield>

              </div>
              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="uofMID" text="Unit of Measure">
                  </kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select id="uofMID" class="noarrow" [multiple]="false"
                               [selectOnTab]="true" formControlName="uofMID" [ngClass]="{
                                'w-100': true
                                }" [searchable]="true" placeholder="Select a Unit of Measure"
                               [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.UnitOfMeasure)"
                               (change)="onSelectBoxChanged($event, 'uofMID', uofMs)">
                      <ng-option *ngFor="let item of uofMs" [value]="item.id">
                        {{ item.name }} | {{ item.uofMSchedule.name }}
                      </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear"
                            (click)="onLookup_unitOfMeasure()" icon="search"></button>
                  </span>
                </kendo-formfield>
              </div>

              <div class="col form-group">
                <kendo-formfield *ngIf="singleCurrency">
                  <kendo-label for="currencyID" text="Currency"></kendo-label>
                  <kendo-textbox class="form-control" formControlName="currencyLabelName"
                                 maxlength="100" disabled="true"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield *ngIf="!singleCurrency">
                  <kendo-label for="currencyID" text="Currency"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select class="noarrow" id="currencyID" [multiple]="false"
                               [selectOnTab]="true" formControlName="currencyID" [ngClass]="{
                                'borderless': true,
                                'w-100': true
                                }" [searchable]="true" placeholder="Select a Currency"
                               [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.Currency)"
                               (change)="onSelectBoxChanged($event, 'currencyID', currencies)">
                      <ng-option *ngFor="let item of currencies" [value]="item.id">
                        {{ item.name }}
                      </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear" (click)="onLookup_currency()"
                            icon="search" [disabled]="singleCurrency"></button>
                  </span>
                </kendo-formfield>
              </div>

              <div class="col form-group">
                <kendo-formfield>
                  <kendo-label for="customerID" text="Customer"></kendo-label>
                  <span class="k-textbox" style="width: 100%;">
                    <ng-select class="noarrow" id="customerID" [multiple]="false"
                               [selectOnTab]="true" formControlName="customerID" [ngClass]="{
                                'borderless': true,
                                'is-invalid': submitted && f.customerID.errors,
                                'w-100': true
                                }" [searchable]="true" placeholder="Select a Customer"
                               [notFoundText]="'Please start typing to search'"
                               (search)="search($event, filters.Customer)"
                               (change)="onSelectBoxChanged($event, 'customerID', customers)">
                      <ng-option *ngFor="let item of customers" [value]="item.id">
                        {{ item?.number }} - {{ item?.name }}
                      </ng-option>
                    </ng-select>
                    <kendo-textbox-separator></kendo-textbox-separator>
                    <button kendoButton type="button" look="clear" (click)="onLookup_customer()"
                            icon="search"></button>
                  </span>
                </kendo-formfield>

              </div>
            </div>
            <div class="col form-group">
              <kendo-expansionpanel [title]="'Document Values'" [expanded]="false">
                <app-pricing-scenario-document-value-list #subgrid
                                                          [pricingScenarioID]='idOfPricingScenario' [pagination]="paginationObject"
                                                          [loading]="loading" [mode]="'subgrid'">
                </app-pricing-scenario-document-value-list>
              </kendo-expansionpanel>
            </div>
          </div>
        </div>
      </ng-template>

    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Results">
      <ng-template kendoTabContent>
        <div id="outputValues" class="card card-primary">
          <div class="card-header">
            <div class="row">
              <div>Output Values</div>
              <div class="col flex-grow-1"></div>
              <div class="align-content-end">
                <button type="button" (click)="this.showInputXML()" class="btn btn-link">
                  View Input XML
                </button>
                <button type="button" (click)="this.showPricingDiagnostic()" class="btn btn-link">
                  View Diagnostics
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col form-group">
                <kendo-label for="lastReturnedUnitPrice" text="Last Returned Unit Price">
                </kendo-label>
                <input readonly class="form-control" formControlName="lastReturnedUnitPrice"
                       id="returnedUnitPrice" />
              </div>

              <div class="col form-group">
                <kendo-label for="lastReturnedPriceScheduleName" text="Last Returned Price Schedule"></kendo-label>
                <input readonly class="form-control" formControlName="lastReturnedPriceScheduleName" id="lastReturnedPriceScheduleName" />
              </div>

              <div class="col form-group">
                <kendo-label for="processStatus" text="Pricing Scenario Status"></kendo-label>
                <!-- <input readonly class="form-control" formControlName="processStatus" id="processStatus" /> -->
                <kendo-label class="form-control text-success" *ngIf="processStatus == null"
                             text="The scenario has not been processed yet."></kendo-label>
                <kendo-label class="form-control text-success" *ngIf="processStatus == 1"
                             text="Price Successfully Found"></kendo-label>
                <kendo-label class="form-control text-danger" *ngIf="processStatus == 0"
                             text="No Price Was Returned. View the Diagnostics"></kendo-label>
              </div>
              <div class="col form-group">
                  <kendo-label for="lastExecutionDateTime" text=" Last Pricing Run Time">
                  </kendo-label>               
                  <input readonly class="form-control" formControlName="lastExecutionDateTime"
                         id="lastExecutionDateTime" type="datetime-local" />
              </div>
            </div>
            <div class="row">
              <div class="col form-group">
                <kendo-label for="kendoGrid" text="Price Adjustments"></kendo-label>
                <kendo-grid #grid="kendoGrid" [data]="gridView" [reorderable]="true" [pageable]="false"
                            [filterable]="false" [resizable]="true" [sortable]="false"
                            kendoGridSelectBy="id">
                  <ng-template kendoGridToolbarTemplate>
                    
                  </ng-template>
                  <kendo-grid-column field="PriceAdjustmentName" title="Name"></kendo-grid-column>
                  <kendo-grid-column field="PriceAdjustmentAmount" title="Amount">
                  </kendo-grid-column>
                  <kendo-grid-column field="DebitGLAccountName" title="Debit GL Name">
                  </kendo-grid-column>
                  <kendo-grid-column field="DebitGLAccountNumber" title="Debit GL Number">
                  </kendo-grid-column>
                  <kendo-grid-column field="CreditGLAccountName" title="Credit GL Name">
                  </kendo-grid-column>
                  <kendo-grid-column field="CreditGLAccountNumber" title="Credit GL Number">
                  </kendo-grid-column>
                  <kendo-grid-column field="UserCanEdit" title="User Can Edit">
                  </kendo-grid-column>
                  <kendo-grid-column field="AdjustmentAffectsMargins" title="Affects Margins">
                  </kendo-grid-column>
                  <kendo-grid-column field="AdjustmentModifiesUnitPrice" title="Modifies Unit Price">
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
